.tenant-move-in-attachment-hover-text {
    color: #4a90e2;
    font-size: 15px;
    transition: color 0.3s;
    cursor: pointer;
  }
  
  .tenant-move-in-attachment-hover-text:hover {
    color: #216dc4;
    /* Change the text color to blue on hover */
  }