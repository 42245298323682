@font-face {
  font-family: "arima";
  src: url("/public/Arima-VariableFont_wght.ttf") format("woff"),
    url("/public/Arima-VariableFont_wght.ttf") format("opentype"),
    url("/public/Arima-VariableFont_wght.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.subTitle {
  text-align: center !important;
  margin: 2% auto !important;
}

.footerMargin {
  text-align: center !important;
  margin: 1% auto !important;
}

.margin {
  margin: 2% auto !important;
}

.uppercase {
  text-transform: uppercase;
  text-align: center;
}

.datePicker input {
  padding: 1% 2% !important;
  font-size: 15px;
}

.button {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
}

.orange-main-button {
  background-color: #ff805d;
  border: none;
  color: #fff;
  text-decoration: none;
}

.violet-main-button {
  background-color: #452b4e;
  border: none;
  color: #fff;
  text-decoration: none;
}

.red-main-button {
  background-color: #cd2222;
  border: none;
  color: #fff;
  text-decoration: none;
}

.round-button {
  border-radius: 5px;
}

.form-row {
  width: 100%;
  display: flex;
  padding: 10px 0;
}

.form-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.form-item-driving-license {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.form-item-label {
  font-weight: 600;
}

.form-item-input {
  font-size: 1rem;
  box-sizing: border-box;
  padding: 15px 10px;
  border-radius: 5px;
  background-color: #ededed;
  border: none;
}

.form-item-error {
  color: #ff0000;
}

.page-container {
  padding: 15px;
}

.container {
  padding: 1%;
  margin: 1% auto;
}

.app {
  /* display: grid;
  grid-template-rows: auto 1fr auto; */
  min-height: 100vh;
}

.app-bar {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 9999;
}

.app-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.app-bar .logo {
  display: flex;
  gap: 1px;
  align-items: center;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 600;

}

.app-bar .logo-icon {
  width: 35px;
  height: 35px;
  padding-bottom: 6px;
}

.app-bar .login-button {
  text-decoration: none;
  background-color: #ff805d;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
}

.app-bar .login-button:hover {
  background-color: #ff805d;
  color: #fff;
}

.app-bar .profile-menu-toggle {
  cursor: pointer;
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
}

.app-bar .profile-menu-content {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(53, 60, 115, 0.15);
  background-color: rgb(243, 243, 243);
}

.app-bar .profile-menu-content-item {
  padding: 10px 20px;
  background: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-content: flex-start;
  color: black;
  text-decoration: none;
  font-size: 1rem;
}

.app-bar .profile-menu-content-item:hover {
  background-color: #e2e2e2;
}

.manager-bar {
  display: flex;
}

.manager-bar-item {
  padding: 5px;
  text-decoration: none;
  font-size: 1.2rem;
}

.home-page .banner {
  background-image: url("/public/images/housing-complex.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.home-page .banner .main-text {
  font-size: 4rem;
  /* font-family: "arima"; */
  margin: 0;
  text-shadow: 0.1em 0.1em 0 hsl(200 50% 30%);
}

.home-page .banner .sub-text {
  text-shadow: 0.1em 0.1em 0 hsl(200 50% 30%);
}

.home-page .properties-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page .properties-section-header {
  padding: 15px;
}

.home-page .properties-section-header h2 {
  margin: 5px;
}

.home-page .property-list {
  display: grid;
  gap: 40px;
  margin-bottom: 50px;
}

.home-page .property-list-item {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  box-shadow: 0 10px 20px rgba(53, 60, 115, 0.15);
  border-radius: 5px;
}

.home-page .property-details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.home-page .property-title {
  font-size: 1.2rem;
  text-transform: capitalize;
  color: #ff805d;
}

.home-page .property-image {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.home-page .rent-amount {
  color: #8cc659;
  font-size: 1rem;
}

.home-page .facilities {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  font-size: 1rem;
  font-weight: 100;
  color: #4a90e2;
}

.home-page .facilities-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.login-page {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
}

.login-page .page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-page .login-form {
  padding: 35px 35px;
  box-shadow: 0 10px 20px rgba(53, 60, 115, 0.15);
  border-radius: 15px;
}

.property-view-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.property-view-page .property-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

.property-view-page .property-left {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: stretch;
}

.property-view-page .property-right {
  text-transform: capitalize;
}

.property-view-page .property-images {
  width: 300px;
  display: flex;
}

.property-view-page .property-images img {
  width: 100%;
}

.property-view-page .property-title {
  margin-top: 0;
  text-transform: capitalize;
}

.property-view-page .facilities {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.property-view-page .facilities-item {
  display: flex;
  gap: 10px;
}

.property-view-page .rent-amount {
  font-size: 1.4rem;
  color: #8cc659;
}

.property-view-page .split-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.property-view-page .application-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.property-view-page .main-application,
.property-view-page .co-application {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.property-view-page .application-header .button {
  padding: 5px;
  font-size: 0.8rem;
  letter-spacing: normal;
}

.tenant-dashboard-page {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.tenant-dashboard-page .dashboard-section {
  box-shadow: 0 10px 20px rgb(53 60 115 / 15%);
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
}

.tenant-dashboard-page .dashboard-section-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.tenant-dashboard-table-head {
  font-weight: bold;
}

.application-address {
  font-size: 15px;
  font-weight: bold;
  color: #4a90e2;
}

.status-success {
  color: #28a745;
}

.status-started {
  color: #4a90e2;
}

.status-withdrawn {
  color: #F2C94C;
}

.tenant-dashboard-page-split-line {
  margin: 50px 0px 50px 0px;
  border: 1px solid grey;
}

.status-text {
  margin-left: 5px;
}

.tenant-dashboard-page .section-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.tenant-dashboard-page .section-button {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.8rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  align-items: center;
}

.tenant-dashboard-page .button-label {
  margin-left: 5px;
}

.tenant-lease-application-view-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tenant-lease-application-view-page .property-details {
  display: flex;
  justify-content: space-between;
  padding: 25px 10px;
  box-shadow: 0 10px 20px rgb(53 60 115 / 15%);
  margin-bottom: 25px;
  border-radius: 5px;
}

.tenant-lease-application-view-page .property-address-line-one {
  font-weight: 600;
  font-size: 1.3rem;
}

.tenant-lease-application-view-page .form-section {
  box-shadow: 0 10px 20px rgba(53, 60, 115, 0.15);
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.tenant-lease-application-view-page .adults-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tenant-lease-application-view-page .minors-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tenant-lease-application-view-page .pets-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tenant-lease-application-view-page .automobiles-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tenant-lease-application-view-page .address-item {
  display: flex;
  gap: 10px;
}

.tenant-lease-application-edit-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tenant-lease-application-edit-page .property-details {
  display: flex;
  justify-content: space-between;
  padding: 25px 10px;
  box-shadow: 0 10px 20px rgb(53 60 115 / 15%);
  margin-bottom: 25px;
  border-radius: 5px;
}

.tenant-lease-application-edit-page .property-address-line-one {
  font-weight: 600;
  font-size: 1.3rem;
}

.tenant-lease-application-edit-page .form-navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  box-shadow: 0 10px 20px rgb(53 60 115 / 15%);
  border-radius: 5px;
}

.tenant-lease-application-edit-page .form-navigation-item {
  width: 100%;
  cursor: pointer;
  padding: 5px;
}

.tenant-lease-application-edit-page .form-navigation-item.complete {
  background-color: #8cc659;
  color: white;
}

.tenant-lease-application-edit-page .form-navigation-item.occupants {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.tenant-lease-application-edit-page .form-navigation-item.agreement {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.tenant-lease-application-edit-page .lease-forms-item {
  display: flex;
  flex-direction: column;
}

.tenant-lease-application-edit-page .form-header {
  margin: 0;
  padding: 10px;
}

.tenant-lease-application-edit-page .form-item-input {
  padding: 5px;
}

.tenant-lease-application-edit-page .form-body {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.tenant-lease-application-edit-page .form-section {
  box-shadow: 0 10px 20px rgba(53, 60, 115, 0.15);
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.tenant-lease-application-edit-page .form-footer {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.tenant-lease-application-edit-page .form-footer-button {
  background-color: #ff805d;
  color: white;
  border-radius: 5px;
  width: 100%;
}

.tenant-lease-application-edit-page .item-remove-button {
  background: none;
  color: red;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 3px;
}

.tenant-lease-application-edit-page .item-remove-button:hover {
  background-color: grey;
}

.tenant-lease-application-edit-page .item-add-button {
  background-color: #ff805d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 1rem;
  text-transform: uppercase;
}

.tenant-lease-application-edit-page .adults-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tenant-lease-application-edit-page .adults-list-item {
  display: flex;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
}

.tenant-lease-application-edit-page .adults-list-item-details {
  padding-right: 15px;
}

.tenant-lease-application-edit-page .adults-form {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tenant-lease-application-edit-page .adults-form .form-row {
  gap: 15px;
}

.tenant-lease-application-edit-page .adults-submit-row {
  justify-content: flex-end;
}

.tenant-lease-application-edit-page .minors-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tenant-lease-application-edit-page .minors-list-item {
  display: flex;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
}

.tenant-lease-application-edit-page .minors-list-item-details {
  padding-right: 15px;
}

.tenant-lease-application-edit-page .minors-form {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tenant-lease-application-edit-page .minors-form .form-row {
  gap: 15px;
}

.tenant-lease-application-edit-page .minors-submit-row {
  justify-content: flex-end;
}

.tenant-lease-application-edit-page .pets-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tenant-lease-application-edit-page .pets-list-item {
  display: flex;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
}

.tenant-lease-application-edit-page .pets-list-item-details {
  padding-right: 15px;
}

.tenant-lease-application-edit-page .pets-form {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tenant-lease-application-edit-page .pets-form .form-row {
  gap: 15px;
}

.tenant-lease-application-edit-page .pets-submit-row {
  justify-content: flex-end;
}

.tenant-lease-application-edit-page .automobiles-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tenant-lease-application-edit-page .automobiles-list-item {
  display: flex;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
}

.tenant-lease-application-edit-page .automobiles-list-item-details {
  padding-right: 15px;
}

.tenant-lease-application-edit-page .automobiles-form {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tenant-lease-application-edit-page .automobiles-form .form-row {
  gap: 15px;
}

.tenant-lease-application-edit-page .automobiles-submit-row {
  justify-content: flex-end;
}

.tenant-lease-application-edit-page .files-row {
  max-width: 240px;
}

.tenant-lease-application-edit-page .signature-canvas {
  border: 2px solid black;
}

.manager-properties-index-page {
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
}

.manager-properties-index-page .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manager-properties-index-page .overview {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 10px 20px rgb(53 60 115 / 15%);
  border-radius: 5px;
  margin-bottom: 20px;
  gap: 5px;
}

.manager-properties-index-page .overview-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  flex: 1;
  border-radius: 5px;
}

.manager-properties-index-page .overview-item .main-text {
  font-size: 3rem;
  display: flex;
  align-items: center;
}

.manager-properties-index-page .properties-list {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.manager-properties-index-page .properties-item {
  padding: 10px;
  box-shadow: 0 10px 20px rgba(53, 60, 115, 0.15);
  border-radius: 5px;
}

.manager-properties-index-page .properties-item-header {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  cursor: pointer;
}

.manager-properties-index-page .properties-item-header-left {
  display: flex;
  flex-direction: column;
}

.manager-properties-index-page .properties-item-header-line {
  display: flex;
  align-items: center;
}

.manager-properties-index-page .properties-item-header-labels {
  display: flex;
  gap: 5px;
}

.manager-properties-index-page .properties-item-header-label {
  padding: 2px 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.manager-properties-index-page .properties-header-button {
  text-decoration: none;
  background-color: #28a745;
  color: white;
  padding: 5px;
  font-size: 0.8rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.manager-properties-index-page .lease-applications-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.manager-properties-index-page .lease-applicant {
  margin-right: 10px;
}

.manager-properties-add-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manager-properties-add-page form {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 600px;
}

.manager-properties-add-page .form-section {
  box-shadow: 0 10px 20px rgba(53, 60, 115, 0.15);
  padding: 20px;
  border-radius: 5px;
}

.manager-properties-add-page .images-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.manager-properties-add-page .images-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manager-properties-add-page .images-list-item img {
  width: 200px;
  height: 200px;
}

.manager-properties-add-page .images-list-item .remove-button {
  margin: 2px;
  cursor: pointer;
}

.manager-properties-edit-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manager-properties-edit-page form {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 600px;
}

.manager-properties-edit-page .form-section {
  box-shadow: 0 10px 20px rgba(53, 60, 115, 0.15);
  padding: 20px;
  border-radius: 5px;
}

.manager-properties-edit-page .images-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.manager-properties-edit-page .images-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e4e4e4;
}

.manager-properties-edit-page .images-list-item img {
  width: 200px;
  height: 150px;
  object-fit: cover;
}

.manager-properties-edit-page .images-list-item .remove-button {
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
  justify-content: center;
  border-top: 1px solid #e4e4e4;
}

.app-footer {
  background-color: #f3f3f3;
}

.app-footer-container {
  padding: 15px 25px;
  /* margin-top: 500px; */
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  opacity: 0.7;
}

/* property-edit */

.property-edit-add-image-icon {
  border: 1px solid rgba(0, 0, 0, .12);
  padding: 2px 15px 2px 15px;
  cursor: pointer;
  border-radius: 5px;
}

.property-edit-add-image-icon:hover {
  background-color: #1976d217;
}

.imgUploadSec {
  padding-bottom: 25px;
}

.property-tab-wrp {
  width: 100%;
  /* padding: 50px; */
}

.property-tab-wrp .react-tabs__tab {
  height: 48px;
  padding: 11px 24px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.property-tab-wrp .react-tabs__tab.react-tabs__tab--selected {
  border: none;
  border-bottom: 4px solid #1890ff !important;
  color: #1890ff;
}

.react-tabs__tab:focus:after {
  display: none !important;
}

.uploadBtn {
  position: relative;
}

.uploadBtn label {
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #e4e4e4;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column-reverse;
}

.uploadBtn label::after {
  content: '';
  background-image: url(../public/images/upload-icon.svg);
  width: 75px;
  height: 75px;
  /* position: absolute;
  left: 0;
  right: 0; */
  margin: 0 auto;
  background-color: #fff;

}

.uploadBtn input {
  opacity: 0;
}

.subHeader {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0px;
}

.optionBox label {
  position: relative;
  display: flex;
  height: 30px;
  align-items: center;
  font-size: 16px;
}

.optionBox label::before {
  content: '';
  display: flex;
  align-self: center;
  margin-right: 15px;
  width: 17px;
  height: 17px;
  border: 1px solid #2e7d32;
  left: 0;
  top: 0px;
  border-radius: 100%
}

.optionBox input[type="radio"]:checked+label::before {
  background-color: #2e7d32;
  background-size: 14px 11px;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: inset 0px 0px 0px 4px #fff;

}

.optionBox input[type="radio"],
.optionBox input[type="checkbox"] {
  opacity: 0;
  display: none;
}

.optionBox input[type="checkbox"]:checked+label::before {
  background-color: #2e7d32;
  background-size: 14px 11px;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: inset 0px 0px 0px 0px #fff;
}

.optionBox input[type="checkbox"]+label::before {
  border-radius: 0%;
}

.optionBox input[type="checkbox"]+label::after {
  content: '';
  width: 5px;
  height: 11px;
  display: block;
  position: absolute;
  left: 7px;
  top: 8px;
  transform: rotate(45deg);
  border: 2px solid #fff;
  border-top: none;
  border-left: none;
}

.optionBoxSec {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.property-tab-wrp textarea.form-item-input {
  background: #fff;
  border: 2px solid #e4e4e4;
  min-height: 175px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  transition: 0.5s all;
}

.propDetails form {
  width: 100%;
}

.property-tab-wrp textarea.form-item-input:hover {
  border-color: #333;
  transition: 0.5s all;
}

.btnSecWrp .radioSecWrp {
  margin-top: 20px;
}

.formSecRow {
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}

.formSecRow>div {
  width: 50%;
  box-shadow: none !important;
}

.formSecRow .form-section-header {
  margin: 0;
}

.formSecRow .form-item {
  position: relative;
}

.formSecRow .form-item span.form-item-label {
  position: absolute;
  left: 0;
  top: 0;
  color: rgba(0, 0, 0, .6);
}


.formSecRow .form-item .form-item-input {
  background: #ffffff;
  border: 2px solid #e4e4e4;
}

.formSecRow .form-item .form-item-input:hover {
  border: 2px solid #333333;
}

.formSecRow .form-item .form-item-input+span {
  font-size: 15px;
  transition: 0.5s all;
  transform: translate(25px, 15px);
}

.formSecRow .form-item-input::placeholder {
  opacity: 0;
}

.formSecRow .form-item-input:not(:placeholder-shown)+span,
.formSecRow .form-item .form-item-input:focus+span {
  transform: translate(20px, -10px);
  cursor: pointer;
  font-size: 13px;
  background: #ffffff;
  padding: 0px 5px;
}

.btnSecWrp {
  padding: 40px 0 25px;
}

.btnSecWrp .optionBoxSec {
  flex-direction: column;
}

.radioSecWrp {
  display: flex;
  gap: 20px;
}

.formBtnSec .form-item {
  display: block;
}

.formBtnSec .form-item .orange-main-button {
  min-width: 200px;
}





/* /. property-edit */

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/*
  * For Tablets
  */
@media (min-width: 600px) {
  .home-page .property-list {
    grid-template-columns: 300px 300px;
  }
}

/*
  * For Desktops
  */
@media (min-width: 768px) {
  .page-container {
    width: 750px;
  }

  .home-page .property-list {
    grid-template-columns: 300px 300px 300px;
  }

  .property-view-page .property-section {
    display: flex;
    flex-direction: row;
  }

  .property-view-page .property-images {
    width: 500px;
    display: flex;
  }

  .property-view-page .split-section {
    flex-direction: row;
  }
}

.react-datepicker-wrapper {
  width: 220px !important;
}

#reviewtable {
  font-family: Arial, Helvetica, sans-serif;

  align-items: center;
  width: 100%;

  table-layout: fixed;
}

#reviewtable td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

#reviewtable th {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 8px;
  text-align: left;
  background-color: #be735e;
  color: white;
}

.relative {
  padding: 10px;
  position: relative;
  background-color: #fff;
  margin: 10px;
}

.navitemdismiss {
  display: inline-block;
  width: 100px;
  height: 30px;
  text-align: center;
  border: gray;
  background-color: #c2bb05;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin: 10px;
}

.navitemsave {
  display: inline-block;
  width: 100px;
  height: 30px;
  text-align: center;
  border: gray;
  background-color: #452b4e;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin: 10px;
}

.navitemdismiss {
  display: inline-block;
  width: 100px;
  height: 30px;
  text-align: center;
  border: gray;
  background-color: #c2bb05;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin: 10px;
}

.navitemaccept {
  display: inline-block;
  width: 100px;
  height: 30px;
  text-align: center;
  border: gray;
  background-color: #ff5d5d;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin: 10px;
}

.textcenter {
  text-align: center;
  padding: 1%;
}

.link {
  color: #ff805d !important;
  text-decoration: none !important;
  font-weight: 600 !important;
}

.link:hover {
  text-decoration: underline !important;
}

.customCard {
  box-shadow: 1px 3px 7px -2px rgba(0, 0, 0, 0.68);
  margin: 1% !important;
  padding: 1% !important;
  width: 300px !important;
}

.MuiTableRow-root {
  line-height: 3;
  font-size: 17px;
}

.MuiTableCell-root {
  min-width: 300px;
}

.css-1ex1afd-MuiTableCell-root label {
  font-size: 17px;
  display: block;
  line-height: 3;
}

.iconImage {
  font-size: 20px !important;
  margin-right: 5px;
}

.rental {
  width: 100%;
}

/* .rental button {
  width: 150px !important
} */
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signbox {
  /* box-shadow: 1px 3px 7px -2px rgba(0, 0, 0, 0.68); */
  max-width: 100%;
  height: 200px;
  border: 1px solid black;
  margin-top: 20px;
  margin-left: 40px;
  border-radius: 5px;
  width: 300px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table-head {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  color: rgba(0, 0, 0, .54);
}

.table-body {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: medium;
}

.tablee {
  width: 100%;
  border-collapse: collapse;
}

.table-headd {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  color: rgba(0, 0, 0, .54);
}

.table-bodyy {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: medium;
}

.manager-dashboard-table {
  width: 100%;
  border-collapse: collapse;
}

.manager-dashboard-head {
  padding-top: 35px;
  padding-bottom: 23px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  color: rgba(0, 0, 0, .54);
}

.manager-dashboard-body {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: medium;
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }
}


.loginbx {
  max-width: 100%;
  margin-top: 3px;
  background-color: #ecebea;
  color: rgb(95, 93, 93);
  height: 50px;
}

.loginavatar {
  padding: 6px;
  text-align: center;
  max-width: 10%;
  float: left;
}

.logintext {
  max-width: 30%;
  padding-left: 130px;
  padding-top: 10px;
}

.signboxx {
  /* box-shadow: 1px 3px 7px -2px rgba(0, 0, 0, 0.68); */
  max-width: 100%;
  height: 200px;
  border: 1px solid black;
  margin-top: 20px;
  border-radius: 5px;
  width: 300px;
}

.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.doc-table {
  width: 100%;
  border-collapse: collapse;
}

.doc-table-head {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  color: rgba(0, 0, 0, .54);
}

.doc-table-body {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: medium;
}

.movein-table {
  width: 100%;
  border-collapse: collapse;
}

.movein-table-body {
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: medium;
}

.button-manage {
  padding-top: 16px;
  margin-top: 50px;
  gap: 5px;
}

.notice-table {
  width: 100%;
  border-collapse: collapse;
}

.notice-table-head {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  color: rgba(0, 0, 0, .54);
}

.notice-table-body {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: medium;
}

.service-table {
  width: 100%;
  border-collapse: collapse;
}

.service-table-head {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  font-size: small;
  color: rgba(0, 0, 0, .54);
}

.service-table-body {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.container {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}


.img-container {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
}

.service-comment-table {
  width: 76%;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
}

.service-comment-table-body {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: medium;
}

.outlined-basic {
  margin-top: -30px;
}

.contractor-flex-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.box-content-align {
  margin-top: auto;
  margin-bottom: auto;
}

.contractor-flex-title {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 18px;
}

.finance-table {
  width: 100%;
  border-collapse: collapse;
}

.finance-table-head {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  color: rgba(0, 0, 0, .54);
}

.finance-table-body {
  padding-left: 6px;
  padding-right: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.manager-finance-table {
  width: 100%;
  border-collapse: collapse;
}

.manager-finance-table-head {
  padding-left: 6px;
  padding-right: 6px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: medium;
  font-weight: bold;
  color: rgba(0, 0, 0, .54);
}

.manager-finance-table-body-1 {
  text-align: left;
  padding-left: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
}

.manager-finance-table-body-2 {
  text-align: center;
  padding-left: 6px;
  padding-right: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.manager-finance-table-body-3 {
  text-align: left;
  padding-left: 6px;
  padding-right: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.manager-finance-table-modals {
  width: 100%;
  border-collapse: collapse;
}

.manager-finance-table-head-modals {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 13px;
  color: rgba(0, 0, 0, .54);
}

.manager-finance-table-body-modals {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 13px;
  padding-bottom: 13px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: small;
}

.manager-finance-table-body-modals-total {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  font-size: medium;
}

.grouping-table {

  width: 100%;
  border-collapse: collapse;

}

.grouping-table-head {
  padding: 30px 20px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: large;
  font-weight: 400;
  color: rgba(0, 0, 0, .54);
}

.grouping-table-body {
  text-align: left;
  padding: 5px 20px;
  border-bottom: 1px solid #ddd;
  color: rgba(0, 0, 0, .54);
}

.paragraph {
  font-size: 20px;
  text-align: center;
  font-weight: bolder;
}

.team-edit-managers-table {
  width: 100%;
  border-collapse: collapse;
}

.team-edit-managers-table-head {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  font-size: small;
  color: rgba(0, 0, 0, .54);
}

.team-edit-managers-table-body {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.active-contractors-link {
  font-size: 15px;
  text-align: center;
  font-weight: bold;
}

.team-contractors-table {
  width: 100%;
  border-collapse: collapse;
}

.team-contractors-table-head {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  font-size: small;
  color: rgba(0, 0, 0, .54);
}

.team-contractors-table-body {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.appbar-centerLink-flex {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.appbar-centerLink-text {
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1px;
}

.tableCell-maxWidth {
  min-width: 0px;
}

.tableCell-maxWidth-1 {
  min-width: 300px;
  max-width: 300px;
}

.property-paragraph {
  color: rgb(151, 151, 151);
  margin-bottom: 5px;
  font-size: 17px;
}

.property-address {
  font-weight: bold;
  margin-bottom: 0px;
}

.property-head-paragraph {
  color: rgb(151, 151, 151);
  font-size: 17px;
}

.property-service-date-badge-cond-green {
  background-color: rgb(11, 119, 11);
  border-radius: 3px;
  min-width: 15px;
  color: white;
  text-align: center;
  font-size: 12px;
  height: 19px;
  margin-left: 5px;
  font-weight: bold;
  cursor: pointer;
}

.property-service-date-badge-cond-yellow {
  background-color: #F2994A;
  border-radius: 3px;
  min-width: 15px;
  color: white;
  text-align: center;
  font-size: 12px;
  height: 19px;
  margin-left: 5px;
  font-weight: bold;
  cursor: pointer;
}

.property-service-date-badge-cond-red {
  background-color: #DC3545;
  border-radius: 3px;
  min-width: 15px;
  color: white;
  text-align: center;
  font-size: 12px;
  height: 19px;
  margin-left: 5px;
  font-weight: bold;
  cursor: pointer;
}

.group-unarchive {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 0px;
  color: #4A90E2;
}

@keyframes up-right {
  0% {
    transform: scale(1);
    opacity: .0
  }

  50% {
    transform: scale (1, 5);
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.circle {
  border-radius: 50%;
  width: 7px;
  height: 7px;
  opacity: .25;
  margin-top: 7px;
  margin-right: 3px;
}

.red {
  background-color: red;
  -webkit-animation: up-right 1.5s infinite;
  -moz-animation: up-right 1.5s infinite;
  -o-animation: up-right 1.5s infinite;
  animation: up-right 1.5s infinite;
}

.lease-overview-tenant-emailandphone-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 3px;
  font-size: 15px;
}

.lease-overview-tenant-firstlastname-field-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px
}

.lease-overview-tenant-phonenademail-field-flex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.lease-overview-tenant-button-flex {
  margin: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
}

.update-contact-email-paragraph {
  font-size: 20px;
  text-align: center;
  font-weight: bolder;
}

.update-contact-details-address1 {
  font-weight: bold
}

.update-contact-details-address2 {
  font-weight: bold
}

.update-contact-details-amount {
  font-weight: bold;
  color: rgb(37, 175, 2)
}

.update-contact-details-bath {
  color: grey;
}

.lease-overview-current-rent-flex {
  display: flex;
  flex-direction: row;
  gap: 10px
}

.lease-invitation-update-error-center {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
}

.property-list-due-amount-text-yellow {
  color: #F2994A;
  font-weight: bold;
  cursor: pointer;
}

.property-list-due-amount-text-red {
  color: #DC3545;
  font-weight: bold;
  cursor: pointer;
}

.manager-finance-table-body-modals-description-text {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 13px;
  padding-bottom: 13px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: small;
  color: rgb(74, 144, 226);
  text-decoration: underline;
}

.property-head-Finance-paragraph {
  color: black;
  font-size: 17px;
}

.navBar-link-flex-style {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #4a90e2;
  cursor: pointer
}

.navBar-mobile-view-link-flex-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px
}

.navBar-mobile-menu-icon {
  color: #fff;
  background-color: #4a90e2;
  border-color: #4a90e2;
  border-radius: 5px;
  margin-right: 5px;
}

.css-o4b71y-MuiAccordionSummary-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  margin: 12px 0;
  -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.leaseApp-Column-Flex {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}

.textAlign-center {
  text-align: center !important;
}

.leaseApp-Column-Flex-child {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 15px;
}

.leaseApp-Column-Flex-child-1 {
  place-content: center space-evenly;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
}

.breedCrump {
  display: flex;
  justify-content: center;
}

.leaseApp-sign-flex-Image {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start space-between;
  align-items: flex-start;
}

.leaseApp-sign-column-flex-Image {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  place-content: flex-start space-between;
  align-items: flex-start;
}

.leaseApp-sign-column-flex-1-Image {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: flex-start;
}

/* Finance View Details Styles */

.finance-view-details-paragraph-1 {
  font-size: 17px;
  text-align: center;
  font-weight: bolder
}

.finance-view-details-paragraph-2 {
  font-size: 22px;
  text-align: center;
  font-weight: bolder
}

.finance-view-details-paragraph-3 {
  font-size: 20px;
  text-align: center;
  font-weight: bolder
}

.finance-view-details-checkbox-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between
}

.finance-view-details-dates-select-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding-top: 20px
}

.finance-view-details-accordion-border {
  border: 2px solid rgba(0, 0, 0, .87)
}

.finance-view-details-accordion-border-child {
  border: 2px solid grey
}

.finance-view-details-table-head {
  font-weight: bold;
}

.finance-view-details-green-color {
  color: rgb(11, 119, 11);
}

.finance-view-details-red-color {
  color: #DC3545;
}

.finance-view-details-blue-color {
  color: #4a90e2;
}

.finance-view-details-yellow-color {
  color: #F2994A;
}

.cursor {
  cursor: pointer;
}

.cursor:hover {
  color: rgb(60, 118, 185);
}

.finance-view-details-icon-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px
}

.finance-view-details-fab-icon-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.finance-view-details-download-flex {
  display: flex;
  justify-content: right;
  padding-top: 20px
}

.finance-view-details-paragraph-no-data {
  font-size: 22px;
  text-align: center;
  font-weight: bolder;
  padding: 50px
}

.file-attach-length-color {
  color: rgb(0, 191, 255)
}

.file-attach-length-flex-alignment {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

/* Prepare Lease Renewal Notice Styles */
.prepare-lease-renewal-head-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
}

.prepare-lease-renewal-head-flex-column {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
}

.prepare-lease-renewal-head-flex-column-1 {
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 10px
}

.prepare-lease-renewal-head-flex-column-2 {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.prepare-lease-renewal-head {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
}

.prepare-lease-renewal-head-child {
  font-weight: bold;
}

.prepare-lease-renewal-head-flex-column-lease {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.prepare-lease-renewal-padding {
  padding-Top: 20px;
  padding-bottom: 20px;
}

/* Finance */

.tableCell-maxWidth-finance {
  min-width: 0px;
}

.finance-page-table-head-grey-color {
  color: rgba(0, 0, 0, .54);
  font-size: 15px;
  font-weight: bold;
}

.finance-category-name-style {
  font-size: 13px;
}

.finance-text-color-green {
  color: rgb(37, 175, 2);
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.finance-text-color-blue {
  color: rgb(74, 144, 226);
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.finance-text-profit-loss-total-color-green {
  color: rgb(37, 175, 2);
  font-size: 15px;
}

.finance-text-profit-loss-total-color-red {
  color: #DC3545;
  font-size: 15px;
}

.finance-text-individual-profit-loss-total-color-green {
  color: rgb(37, 175, 2);
  font-size: 15px;
  font-weight: bold;
}

.finance-text-individual-profit-loss-total-color-red {
  color: #DC3545;
  font-size: 15px;
  font-weight: bold;
}

.finance-text-color-blue:hover {
  color: rgb(60, 118, 185);
}

.finance-text-color-red {
  color: #DC3545;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.finance-text-color-orange {
  color: #FFA500;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.finance-travel-expense-flex {
  display: flex;
  flex-direction: column;
}

.finance-travel-expense-miles-color-head {
  color: #FFA500;
  font-size: 13px;
}

.finance-travel-expense--miles-color {
  color: orangered;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.finance-add-group-expense-attachment-hover-text {
  color: #4a90e2;
  font-size: 15px;
  transition: color 0.3s;
}

.finance-add-group-expense-attachment-hover-text:hover {
  color: #216dc4;
  /* Change the text color to blue on hover */
}

.finance-add-due-dialog-box-textfield-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.finance-add-due-dialog-box-textfield-flex-width {
  flex: 1 1 45%;
}

.finance-add-due-dialog-box-textfield-flex-width-description {
  flex: 1 1 70%;
}

.finance-add-due-dialog-box-textfield-flex-width-type {
  flex: 1 1 25%;
}

.finance-edit-due-dialog-box-textfield-flex-width {
  flex: 1 1 0%;
}

.finance-add-due-dialog-box-textfield-flex-action-button {
  display: flex;
  flex-direction: row;
  justify-content: right;
  flex-wrap: wrap;
  gap: 10px;
}

.finance-edit-due-dialog-box-textfield-flex-action-button-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.finance-payment-recieved-dialog-first-flex {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 20px
}

.finance-payment-recieved-dialog-second-flex {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  font-weight: bold;
}

.finance-payment-recieved-dialog-first-flex-grow {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  box-sizing: border-box;
}

.finance-payment-recieved-dialog-second-flex-grow {
  flex-grow: 3;
  flex-shrink: 1;
  flex-basis: 0%;
  box-sizing: border-box;
}

/* Users Page */

.users-page-managers {
  font-size: 22px;
  text-align: center;
  font-weight: bolder;
  padding-bottom: 30px;
  margin: 0px;
}

.users-page-managers-table-head {
  font-weight: bold;
}

/* Service Request View */

.hover-blue-text {
  color: black;
  /* Set the initial text color */
  transition: color 0.3s;
  /* Add a smooth transition effect */
}

.hover-blue-text:hover {
  color: #4183c4;
  /* Change the text color to blue on hover */
}

/* Update Notice Templates */

.update-notice-templates-heading {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.update-notice-templates-link {
  font-size: 18px;
  text-align: center;
  padding-bottom: 20px;
}

.update-notice-templates-heading-child {
  font-size: 18px;
  font-weight: bold;
}

.update-notice-templates-quill-editor {
  width: 100%;
  height: 175px;
}

.update-notice-templates-button-flex-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 100px;
  gap: 10px;
  align-items: center;
}

.update-notice-templates-button-flex-2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.add-notice-templates-button-flex-1 {
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-top: 100px;
}

.add-notice-templates-table-head-color {
  color: rgba(0, 0, 0, .54);
}

.add-notice-templates-table-desc-font {
  font-size: 14px;
}


/* Preview Notice */

.preview-notice-heading {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 20px;
}

.notice-preview-signbox {
  max-width: 100%;
  height: 200px;
  border: 1px solid black;
  margin-top: 20px;
  border-radius: 5px;
  width: 300px;
}

/* Lease Overview */

.lease-overview-endlease-dialog-action-text {
  font-size: 15px;
  text-align: center;
  padding: 10px;
  color: #DC3545;
}

.privacy-policy-heading-bottom-line {
  border-bottom: 1px solid black;
  padding: 30px 0px 30px 0px;
}

.privacy-policy-heading {
  font-size: 22px;
  font-weight: bold;
}

.privacy-policy-text {
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0px 0px 0px;
}

.privacy-policy-text-lighter {
  font-size: 15px;
  font-weight: lighter;
  padding: 0px 0px 0px 0px;
  margin: 0px;
}

.privacy-policy-text-left-margin {
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0px 10px 50px;
}

.privacy-policy-text-left-margin-with-lighter {
  font-size: 15px;
  font-weight: lighter;
  margin: 20px 0px 0px 50px;
}

.privacy-policy-blue-color {
  color: #4a90e2;
}

/* Start Application */

.start-application-page-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px
}

.start-application-page-container-width {
  width: 100%;
  max-width: 1350px;
  flex-grow: 1
}

.start-application-heading-title {
  font-size: 22px;
  text-align: center;
  font-weight: bolder;
  padding: 20px 0px 20px 0px;
}

.start-application-section-1-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px
}

.start-application-flex-grow {
  flex-grow: 1;
}

.start-application-image-size {
  width: 100%;
  height: 400px;
}

.start-application-section-1-details-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px
}

.start-application-section-1-details-address-text {
  font-size: 17px;
  font-weight: bold;
}

.start-application-section-1-details-rent-amount {
  font-size: 17px;
  font-weight: bold;
  color: #ff805d;
}

.start-application-section-1-details-icon-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px
}

.start-application-section-2-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.start-application-sub-heading-title {
  font-size: 20px;
  font-weight: bold;
  padding-top: 20px;
}

.start-application-add-applicant-text {
  color: grey;
  font-size: 12px;
}

.start-application-coapplicant-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px
}

.start-application-add-coapplicant-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px
}


.warnig-move-in-desc {
  color: #FFA500;
}

.manager-move-out-signbox {
  /* box-shadow: 1px 3px 7px -2px rgba(0, 0, 0, 0.68); */
  max-width: 100%;
  height: 200px;
  border: 1px solid black;
  margin-top: 20px;
  border-radius: 5px;
  width: 300px;
}

.manager-move-out-primary {
  color: #4a90e2;
}

/* Profile Image */

/* Container needed to position the overlay. Adjust the width as needed */
.profile-image-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

/* Make the image to responsive */
.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /* object-fit: contain */
}

/* The overlay effect (full height and width) - lays on top of the container and over the image */
.overlay {
  position: absolute;
  height: 150px;
  width: 150px;
  opacity: 0;
  transition: .3s ease;
  background-color: black;
  border-radius: 50%;
}

/* When you mouse over the container, fade in the overlay icon*/
.profile-image-container:hover .overlay {
  opacity: 0.5;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.profile-image-container-delete-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.manager-properties-navigation-link-styles {
  font-weight: bold;
  cursor: pointer;
}

.manager-properties-navigation-link-styles:hover {
 color: #4A90E2;
}