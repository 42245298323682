.load-wrapp {
 
    padding: 10px;
     text-align: center;
   }
   
   
   .load-wrapp:last-child {
     margin-right: 0;
   }
   
   
   .letter {
     float: left;
     font-size: 16px;
     font-weight: bold;
   }
   
   
   .load-progress .letter {
     animation-name: loadingF;
     animation-duration: 1.6s;
     animation-iteration-count: infinite;
     animation-direction: linear;
   }
   
   .l-1 {
     animation-delay: 0.48s;
   }
   .l-2 {
     animation-delay: 0.6s;
   }
   .l-3 {
     animation-delay: 0.72s;
   }
   .l-4 {
     animation-delay: 0.84s;
   }
   .l-5 {
     animation-delay: 0.96s;
   }
   .l-6 {
     animation-delay: 1.08s;
   }
   .l-7 {
     animation-delay: 1.2s;
   }
   .l-8 {
     animation-delay: 1.32s;
   }
   .l-9 {
     animation-delay: 1.44s;
   }
   .l-10 {
     animation-delay: 1.56s;
   }
   
   @keyframes loadingF {
     0% {
       opacity: 0;
     }
     100% {
       opacity: 1;
     }
   }