.tennat-lease-view-sr-state-green {
    color: rgb(37, 175, 2);;
}

.tennat-lease-view-sr-state-orange {
    color: #FFA500;
}

.tennat-lease-view-sr-title {
    color: rgb(74, 144, 226);
    cursor: pointer;
}