.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    /* border-color: grey !important; */
    /* border-width: 1px !important; */
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}

.css-1pnmrwp-MuiTypography-root {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400 !important;
    font-size: 1.5rem !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: black !important;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: black !important;
    font-weight: bold !important;
    /* font-size: 1.5rem !important; */
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: bold !important;
    /* font-size: 1rem; */
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    /* color: black !important; */
    -webkit-text-fill-color: black !important;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding-left: 14px;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    /* border-color: grey !important; */
    /* border-width: 1px !important; */
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}